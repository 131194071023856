export default (route) => {
  const ACCESS_KEY = 'ibm';

  const localUtmSource = localStorage.getItem('utm_source');

  if (localUtmSource === ACCESS_KEY) {
    return true;
  }

  const queryUtmSource = route.query?.utm_source
    ? route.query.utm_source.toLowerCase()
    : null;

  if (queryUtmSource === ACCESS_KEY) {
    localStorage.setItem('utm_source', queryUtmSource);
    return true;
  }

  return false;
};
