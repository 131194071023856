import useSession from '@/use/session';
import useInstance from '@/use/instance';

const {
  saveSession,
} = useSession();

const {
  launchInstance,
} = useInstance();

export default async function submitFinalStep({ currentStep, session, domo }) {
  saveSession();
  await launchInstance(currentStep, session, domo);
}
