import usePassword from '@/use/password';

const { validatePassword } = usePassword();

const isEmail = (email) => {
  const emailRegex = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
  return emailRegex.test(email);
};


const isPassword = (password) => {
  const { isValid } = validatePassword(password);
  return isValid;
};


const isWeakPhone = (phone) => {
  const digits = phone.match(/\d/g) || [];
  return digits.length > 7 && digits.length < 16;
};


// Exports
export default function useValidate() {
  return {
    isEmail,
    isPassword,
    isWeakPhone,
  };
}
