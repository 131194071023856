import { clamp } from 'lodash';
import { $t } from '@/i18n';

// Reference: https://stackoverflow.com/a/21456918/3000726
// Special Character List: https://owasp.org/www-community/password-special-characters

function validatePassword(password) {
  let isValid = true;
  let errorMessage = null;

  const criteria = [
    {
      label: `${$t('shared.passwordMinimum')} (${clamp(password.replace(/\s/g, '').length, 0, 8)}/8)`,
      isSatisfied: password.replace(/\s/g, '').length > 7,
      errorMessage: $t('shared.passwordMinimumRequired'),
    },
    {
      label: $t('shared.passwordUppercase'),
      isSatisfied: /^(?=.*[A-Z])/.test(password),
      errorMessage: $t('shared.passwordUppercaseRequired'),
    },
    {
      label: $t('shared.passwordLowercase'),
      isSatisfied: /^(?=.*[a-z])/.test(password),
      errorMessage: $t('shared.passwordLowercaseRequired'),
    },
    {
      label: $t('shared.passwordNumber'),
      isSatisfied: /^(?=.*\d)/.test(password),
      errorMessage: $t('shared.passwordNumberRequired'),
    },
    {
      label: $t('shared.passwordSpecial'),
      isSatisfied: /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/.test(password),
      errorMessage: $t('shared.passwordSpecialRequired'),
    },
  ];

  const firstInvalidCriteria = criteria.find((c) => !c.isSatisfied);

  if (firstInvalidCriteria) {
    isValid = false;
    errorMessage = firstInvalidCriteria.errorMessage;
  }

  const invalidCharactersArray = password.match(/[^a-zA-Z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g);

  if (!firstInvalidCriteria && invalidCharactersArray) {
    isValid = false;
    errorMessage = `Invalid character - ${invalidCharactersArray[0]}`;
  }

  return {
    isValid,
    criteria,
    errorMessage,
  };
}


// Exports
export default function usePassword() {
  return {
    validatePassword,
  };
}
