import {
  ref,
  computed,
} from 'vue';
import Http from '@/services/http';
import useUtils from '@/use/utils';
import useAnalytics from '@/use/analytics';

const { awaitTimeout } = useUtils();
const { trackEvent, fireMarketingPixels } = useAnalytics();

// Constants
const MAXIMUM_LAUNCH_ATTEMPTS = 30; // <- i.e. seconds


// Properties
const launchAttempts = ref(0);
const isInstanceReady = ref(false);


// Computed Properties
const isInstanceTakingTooLong = computed(() => launchAttempts.value > 5);


// Methods
const goToInstance = (isReady, { currentStep, session, domo }) => {
  if (isReady) {
    // 1) It's ready
    trackEvent('finished free signup', currentStep, session);
  } else {
    // 2) Not ready and too many checks
    trackEvent('viewed free trial hold screen', currentStep, session);
    trackEvent('failed free trial signup', currentStep, session);
  }
  /*
   * setTimeout waits for trackEvent^ to finish because it's not a promise
   * and we have no way of knowing when it's finished
   */

  // 3) - Ultimately, redirect wether ready or not
  return setTimeout(() => {
    /**
     * @TODO refactor /activate to be POST request and receive redirect and product SID in response
     * Then use window.location to forward user to final destination
     */
    window.location = `/api/free/v2/activate?uffid=${domo.uffid}&email=${session.email.value}&lang=${domo.lang}&navigatorLang=en-US&token=${localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN_NAME)}`;
  }, 1500);
};

const checkInstanceReady = async (currentStep, session, domo) => {
  // 1) Check if instance is ready
  try {
    const { data } = await Http.get(`/api/free/v2/instanceCheck?email=${session.email.value}&uffid=${domo.uffid}`);
    isInstanceReady.value = data.is_ready || false;
  } catch (error) {
    console.error('ERROR CHECKING IF INSTANCE IS READY: ', error);
  }

  // 2) If ready OR if too many checks
  if (isInstanceReady.value || launchAttempts.value >= MAXIMUM_LAUNCH_ATTEMPTS) {
    goToInstance(isInstanceReady.value, { currentStep, session, domo });
  } else {
    // 3) Instance NOT ready, check again..
    launchAttempts.value += 1;
    await awaitTimeout(1000);
    await checkInstanceReady(currentStep, session, domo);
  }
};

const launchInstance = async (currentStep, session, domo) => {
  // 1. Kickoff instance activation. This can take a while, so call it first
  try {
    await Http.post('/api/free/v2/startActivate', {
      uffid: domo.uffid,
    });
  } catch (error) {
    console.error('ERROR ACTIVATING INSTANCE: ', error);
  }

  // 2. Fire marketing pixels
  fireMarketingPixels();

  // 3. Update user meta
  try {
    await Http.post('/api/free/v2/updateUserMeta', {
      uffid: domo.uffid,
      email: session.email.value,
    });
  } catch (error) {
    console.error('ERROR UPDATING USER META: ', error);
  }

  // 4. Check if the instance is ready (recursive)
  await checkInstanceReady(currentStep, session, domo);
};


// Exports
export default function useInstance() {
  return {
    launchAttempts,
    maxLaunchAttempts: MAXIMUM_LAUNCH_ATTEMPTS,
    isInstanceReady,
    isInstanceTakingTooLong,
    launchInstance,
  };
}
