import useSession from '@/use/session';
import useAnalytics from '@/use/analytics';

const {
  saveSession,
} = useSession();

const {
  trackEvent,
} = useAnalytics();

export default async function submitPersonal({ currentStep, session }) {
  trackEvent('submitted personal info', currentStep, session);
  saveSession();
}
